<template>
    <dashboard-main>
        <div slot="main-content">
            <div class="admin-page-title">
                <small>BOOKS</small>
            </div>

            <div class="card">
                <div class="card-body">
                    <h6 class="mb-4">Stories Collection</h6>
                    <div class="table-responsive">
                        <table class="table" width="100%">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Author</th>
                                    <th scope="col">Document</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(story, index) in stories" :key="index">
                                    <td>
                                        <b>#{{index + 1}}</b>
                                    </td>
                                    <td>
                                        USD {{story.price}}
                                    </td>
                                    <td>{{story.title}}</td>
                                    <td>{{story.author}}</td>
                                    <td>
                                        <a :href="story.pdfdoc" target="_blank">View</a>
                                    </td>
                                    <td>
                                        <button class="btn btn-success btn-details mr-2" @click="getStory(story.id)">Edit</button>
                                        <button class="btn btn-danger btn-details" @click="delStory(story.id)">Delete</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <modal v-if="storyId !== null" @close="closeUpdate" size="800px">
                <!--
                you can use custom content here to overwrite
                default content
                -->
                <h5 slot="header">Update story</h5>

                <div slot="body">
                    <EditStory :story="story" @close="closeUpdate" />
                </div>
            </modal>
        </div>
    </dashboard-main>
</template>

<script>
import DashboardMain from "@/components/layouts/DashboardMain"
import EditStory from '@/components/EditStory'
import Modal from "@/components/Modal"
import { mapState } from 'vuex'

export default {
    name: "Stories",
    components: {
        DashboardMain,
        EditStory,
        Modal
    },
    data() {
        return {
            stories: [],
            storyId: null,
            story: {}
        }
    },
    mounted() {
        this.get()
        this.$store.commit('setToken')
    },
    methods: {
        get() {
            this.$axios.get(`${this.$apiurl}/stories/`)
            .then(res => {
                this.stories = res.data
            })
            .catch(error => {
                console.log(error.response)
            })
        },
        async getStory(id) {
            try {
                const res = await this.$axios.get(`${this.$apiurl}/stories/${id}/`, {
                    headers: {
                        Authorization: `Token ${this.token}`
                    }
                })
                
                this.story = res.data
                this.storyId = id
            }catch(error) {
                console.log(error.response)
            }
        },
        async delStory(id) {
            this.$swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover it!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    this.$axios.delete(`${this.$apiurl}/stories/${id}/`, {
                        headers: {
                            Authorization: `Token ${this.token}`
                        }
                    })
                    .then(() => {
                        this.get()
                        this.$swal("Poof! Story has been deleted!", {
                            icon: "success",
                        });
                    })
                    .catch(error => {
                        console.log(error.response)
                    })
                }
            });

            /*try {
                await this.$axios.delete(`${this.$apiurl}/stories/${id}/`, {
                    headers: {
                        Authorization: `Token ${this.token}`
                    }
                })
                
                this.get()
            }catch(error) {
                console.log(error.response)
            }*/
        },
        closeUpdate() {
            this.storyId = null 
            this.story = {}
            this.get()
        }
    },
    computed: {
        ...mapState([
            'token'
        ])
    }
}
</script>