<template>
    <main>
        
        <section class="login-wrapper">
            <div class="container py-4">
                
                <div class="row align-items-center">
                    <div class="col-md-4 col-12">
                        <div class="bg-white login-container">
                            <h1>Sign in</h1>
                            <form>
                                <div class="form-group">
                                    <label for="">Username</label>
                                    <input type="text" v-model="username" placeholder="Your username" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label for="">Password</label>
                                    <input type="password" v-model="password" placeholder="***********" class="form-control">
                                </div>
                                <div class="form-group mt-4">
                                    <button type="button" class="btn btn-dark rounded" @click="signIn">Sign in</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <Footer />
    </main>
</template>

<script>
import Footer from '@/components/Footer'

export default {
    name: "Login",
    components: {
        Footer
    },
    data() {
        return {
            username: '',
            password: '',
        }
    },
    methods: {
        signIn() {
            if(!this.username || !this.password) {
                return this.$swal({
                    title: "Error!",
                    text: "Incomplete form data",
                    icon: "warning",
                })
            }

            this.$axios.post(`${this.$apiurl}/login/`, {
                username: this.username,
                password: this.password
            })
            .then(res => {
                localStorage.token = res.data.token
                this.$store.commit('setToken', res.data.token)
                this.$router.push('/dashboard')
            })
            .catch(error => {
                console.log(error.response)
            })
        }
    }
}
</script>