<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container" :style="`width: ${size}`">

                    <div class="modal-header">
                        <slot name="header"></slot>
                    </div>

                    <div class="modal-body">
                        <slot name="body"></slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            <button class="modal-default-button btn" @click="$emit('close')">
                                Close
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Modal',
    props: [
        'size',
    ],
}
</script>

<style lang="scss" scoped>
    /* Modal */
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 92%;
        max-width:380px;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
    }

    .modal-header h3 {
        margin-top: 0;
        color: var(--main-color);
        border-bottom: 1px solid #ccc;
        padding-bottom: 1rem;
    }

    .modal-body {
        margin: 20px 0;
        max-height: 400px !important;
        overflow-y: auto;
    }

    .modal-body::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    .modal-body::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }

    /* Handle */
    .modal-body::-webkit-scrollbar-thumb {
        background: var(--main-dark); 
        border-radius: 8px;
    }

    /* Handle on hover */
    .modal-body::-webkit-scrollbar-thumb:hover {
        background: #b30000; 
    }

    .modal-footer {
        text-align: right;
    }

    .modal-default-button {
        background-color: #333;
        color: #fff;
    }


    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    @media only screen and (min-width: 510px) {
        .modal-container {
            max-width: 80%;
            max-width: 960px;
            margin: 0px auto;
            padding: 20px 30px;
            background-color: #fff;
            border-radius: 2px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
            transition: all 0.3s ease;
        }
    }
</style>