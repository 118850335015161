<template>
  <div id="app">
    <Nav :bgtype="$route.path === '/'? 'bg-main' : ''" v-if="!$route.meta.admin && $route.meta.admin !== true" />
    <router-view></router-view>

    <div class="loading-overlay" v-if="isLoading">
      <div class="lds-hourglass"></div>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Nav
  },
  computed: {
    ...mapState([
      'isLoading'
    ])
  }
}
</script>
