<template>
  <main>

    <section class="page-header py-4 bg-grey">
      <div class="container">
        <h2>Checkout</h2>
      </div>
    </section>

    <section class="py-4 bg-grey">
      <div class="container" v-if="alert">
        <div class="alert alert-info">
          <p>
            <span class="ti-check pr-2"></span>
            <small><b>Your book order process is complete! We will proceed to getting the shipment across to
              you</b></small>
          </p>
        </div>
      </div>

      <div class="container" v-else>
        <div class="card">
          <div class="table-responsive" v-if="cartCount > 0">
            <table class="table">
              <thead>
              <tr>
                <th scope="col">Cover</th>
                <th scope="col">Childname</th>
                <th scope="col">Personal message</th>
                <th scope="col">Price</th>
                <th scope="col">remove</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in cartItems" :key="index">
                <td>
                  <img :src="item.cover" width="50px" height="50px" alt="">
                </td>
                <td>{{ item.childname }}</td>
                <td>{{ item.personalMsg }}</td>
                <td>USD {{ item.story.price }}</td>
                <td>
                  <small class="" @click="dropItem(index)">Remove</small>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="mt-4 card">
          <div class="delivery-form p-3">
            <h6 class="mb-0">Delivery address</h6>
            <div class="mb-4">
              <small>Fill in the delivery address information</small>
            </div>

            <div class="row">
              <div class="col-md-6 col-12">
                <label for="">Name</label>
                <div class="form-group">
                  <input type="text" v-model="checkout.name" class="form-control" placeholder="Full name">
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label for="">Email</label>
                  <div class="form-group">
                    <input type="email" v-model="checkout.email" class="form-control" placeholder="example@yahoo.com">
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-12">
                <label for="">City</label>
                <div class="form-group">
                  <input type="text" class="form-control" v-model="checkout.city" placeholder="City of residence">
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label for="">Address</label>
                  <div class="form-group">
                    <input type="text" class="form-control" v-model="checkout.address" placeholder="Your address">
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-12">
                <label for="">Phone</label>
                <div class="form-group">
                  <input type="text" class="form-control" v-model="checkout.phone" placeholder="090xxxxxxxx">
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label for="">Zip code</label>
                  <div class="form-group">
                    <input type="text" class="form-control" v-model="checkout.zip" placeholder="7xxxxx">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pb-3 pl-3">
            <button class="btn btn-primary" data-toggle="modal" data-target="#stripePayoutModal">Make Payment</button>
          </div>
        </div>
      </div>
    </section>

    <div ref="modal" class="modal" data-backdrop="static" id="stripePayoutModal" tabindex="-1" role="dialog"
         aria-labelledby="stripePayoutModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span
                class="modal-title col-12 text-center Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">Powered by <svg
                class="InlineSVG Icon Footer-PoweredBy-Icon Icon--md" focusable="false" width="33" height="15"><g
                fill-rule="evenodd"><path
                d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path></g></svg></span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-0">
            <p class="text-center"><img :src="require('@/assets/images/mastercard.svg')" alt=""> and <img
                :src="require('@/assets/images/visa.svg')" alt=""> Supported</p>
            <StripeElementCard ref="stripe" :hidePostalCode="true" :pk="stripePk" @token="callback"
                               @error="paymentError"/>
          </div>
          <span class="text-center">Payment is <img :src="require('@/assets/images/pci.svg')" alt=""> protected</span>
          <div class="modal-footer border-top-0">
            <button ref="closeModal" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button v-if="!loading" type="button" class="btn btn-primary" ref="submitBtn" @click="generateToken">
              Submit
            </button>
            <button v-else disabled class="btn btn-primary"><i class='fa fa-circle-o-notch fa-spin'></i>Submiting...
            </button>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </main>
</template>

<script>
import Footer from '@/components/Footer'
// import paystack from 'vue-paystack'
import { StripeElementCard } from '@vue-stripe/vue-stripe';

export default {
  name: 'Checkout',
  components: {
    Footer,
    // paystack,
    StripeElementCard
  },
  data() {
    return {
      loading: false,
      alert: false,
      stripePk: "pk_test_51IXSTSFVlxYTBXuAVbpOEvJa27sMfqRhsAFZqjYp4Ilv6x7nFf74Uy0XrKoz9azRFaAOm5DjLctau0WyZP3ajIFI00wze8g6Eu",
      checkout: {
        name: '',
        email: '',
        city: '',
        address: '',
        phone: '',
        zip: ''
      }
    }
  },
  mounted() {
    let cart = this.$store.getters.getCart
    if (!cart.length && alert) this.$router.push('/')
  },
  methods: {
    dropItem(id) {
      this.$store.commit('dropFromCart', id)
    },
    callback: function (token) {
      console.log(token);
      const { name, email, phone, city, address, zip } = this.checkout
      const formdata = new FormData()

      formdata.append('name', name)
      formdata.append('email', email)
      formdata.append('address', address)
      formdata.append('city', city)
      formdata.append('phone', phone)
      formdata.append('zip', zip)
      formdata.append('amount', +this.cartTotal * 100)
      formdata.append('token', token.id)
      formdata.append('details', JSON.stringify(this.cartItems))

      this.$axios.post(`${this.$apiurl}/order/new-order`, formdata)
          .then(res => {
            console.log(res)
            // this.$store.commit('emptyCart')
            this.$swal({
              title: "Checkout Success!",
              text: "Your book order process is complete! We will proceed to getting the shipment across to you",
              icon: "success",
            }).then(() => {
              this.$store.dispatch('emptyCart')
              this.$refs.closeModal.click()
              this.$router.push('/')
            })
          })
          .catch(error => {
            console.log(error.response)
            this.$swal({
              title: "Error",
              text: error.response.message || "Something went worng with your payment! Please try again",
              icon: "warning"
            })
            this.loading = false
          })
    },
    generateToken() {
      this.loading = true
      this.$refs.stripe.submit()
    },
    paymentError() {
      this.loading = false
      this.$swal({
        title: "Error!",
        text: "Card Failed, Try Again!",
        icon: "warning"
      })
    },
    close() {

    },
  },
  computed: {
    cartItems() {
      let cart = this.$store.getters.getCart
      return cart
    },
    cartCount() {
      let cart = this.$store.getters.getCart
      return cart.length
    },
    cartTotal() {
      let cart = this.$store.getters.getCart
      let total = 0

      cart.map(item => {
        total += Number(item.story.price)
      })
      return total
    },
    reference() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))

      return text;
    },
    validateCheckoutData() {
      const { name, email, city, address, phone, zip } = this.checkout

      return !name || !email || !city || !address || !phone || !zip
    }
  }
}
</script>

<style lang="scss" scoped>
.payButton.checkout-btn {
  display: inline !important;
  max-width: 210px !important;
}
</style>
