<template>
    <div class="dashboard-wrapper">
        <input type="checkbox" id="admin-menu-toggle">
        <aside class="sidebar-wrapper">
            <div class="admin-brand">
                <h6><span class="ti-book"></span> <span>Black Baby World</span></h6>
            </div>
            <div class="sidebar">
                <div class="mb-3">
                    <small class="text-white">MENU</small>
                </div>
                <ul>
                    <li>
                        <router-link to="/dashboard"><span class="ti-home"></span> <span>Dashboard</span></router-link>
                    </li>
                    <li>
                        <router-link to="/"><span class="ti-home"></span> <span>Landing page</span></router-link>
                    </li>
                    <li>
                        <router-link to="/stories-admin"><span class="ti-book"></span> <span>Stories</span></router-link>
                    </li>
                    <li>
                        <router-link to="/new-story"><span class="ti-plus"></span> <span>New Story</span></router-link>
                    </li>
                  <li>
                    <router-link to="/testimonies-admin"><span class="ti-face-smile"></span> <span>Testimonies</span></router-link>
                  </li>
                  <li>
                        <router-link to="/new-testimony"><span class="ti-plus"></span> <span>New testimony</span></router-link>
                    </li>
                    <li>
                        <router-link to="/orders"><span class="ti-agenda"></span> <span>Orders</span></router-link>
                    </li>
                    <!--<li>
                        <router-link to="/customers"><span class="ti-user"></span> <span>Customers</span></router-link>
                    </li>-->
                    <li>
                        <a href="" @click="signOut($event)"><span class="ti-power-off"></span> <span>Signout</span></a>
                    </li>
                </ul>
            </div>
        </aside>
        <main>
            <div class="admin-nav">
                <div>
                    <label for="admin-menu-toggle"><span class="ti-menu-alt"></span></label>
                </div>
                <div class="search-user">
                    <input type="text" placeholder="Search" class="nav-search">
                    <img :src="require('@/assets/images/avatar.jpg')" class="rounded-circle" height="40px" width="40px" alt="">
                </div>
            </div>
            <div class="dashboard-main">
                <slot name="main-content"></slot>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: "DashboardMain",
    methods: {
        signOut(event) {
            event.preventDefault();
            
            localStorage.removeItem('token')
            this.$router.push('/signin')
        }
    }
}
</script>