import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'

//Global CSS
import '@/assets/css/main.scss'

//Animate on Scroll
import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles
// ..
AOS.init();

//Vue WYSIWYG
import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {}); // config is optional. more below

//ImageKit io
import ImageKit from "imagekitio-vue"

Vue.use(ImageKit, {
  urlEndpoint: "https://ik.imagekit.io/hooli/", // Required. Default URL-endpoint is https://ik.imagekit.io/your_imagekit_id
  publicKey: "public_cDeJKZD9k9sKYEEgkYtP3Gh/3yA=", // optional
  // transformationPosition: "path" // optional
})


//Vue sweet alert
import VueSwal from 'vue-swal'
Vue.use(VueSwal)

//Global vars
Vue.prototype.$axios = axios
Vue.prototype.$apiurl = `https://tribal-books.herokuapp.com`

//router
import router from '@/router'

//store
import store from '@/store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
