import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)

// Define route components.
import Home from '@/pages/Home'
import Blog from '@/pages/Blog'
import Gallery from '@/pages/Gallery'
import Contact from '@/pages/Contact'
import About from '@/pages/About'
import Login from '@/pages/Login'
import StoriesUsers from '@/pages/Stories'
import Create from '@/pages/Create'
import Checkout from '@/pages/Checkout'
//import CheckoutDone from '@/pages/CheckoutDone'
import BookDetail from '@/pages/BookDetail'
import Dashboard from '@/pages/admin/Dashboard'
import Stories from '@/pages/admin/Stories'
import Customers from '@/pages/admin/Customers'
import Orders from '@/pages/admin/Orders'
import NewStory from '@/pages/admin/NewStory'


// Define some routes
const routes = [
    { path: '/', component: Home },
    { path: '/blog', component: Blog },
    { path: '/gallery', component: Gallery },
    { path: '/contact', component: Contact },
    { path: '/about', component: About },
    { path: '/stories', component: StoriesUsers },
    { path: '/create-your-book', component: Create },
    { path: '/checkout', component: Checkout },
    //{ path: '/checkout-success', component: CheckoutDone },
    { path: '/story/:id', component: BookDetail },
    { path: '/signin', component: Login },
    { path: '/dashboard', meta: { requiresAuth: true, admin: true }, component: Dashboard },
    { path: '/stories-admin', meta: { requiresAuth: true, admin: true }, component: Stories },
    { path: '/customers', meta: { requiresAuth: true, admin: true }, component: Customers },
    { path: '/orders', meta: { requiresAuth: true, admin: true }, component: Orders },
    { path: '/new-story', meta: { requiresAuth: true, admin: true }, component: NewStory },
    { path: '/new-testimony', meta: { requiresAuth: true, admin: true }, component: () => import("@/pages/admin/NewTestimony") },
    { path: '/testimonies-admin', meta: { requiresAuth: true, admin: true }, component: () => import("@/pages/admin/Testimonies") },
]

// Create the router instance and pass the `routes` option
const router = new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
    const token = localStorage.token

    if(to.meta.requiresAuth && to.meta.requiresAuth === true && !token) {
        next('/signin')
    }

    if(token && to.path === '/signin') {
        next('/dashboard')
    }

    next()
});

export default router