<template>
    <dashboard-main>
        <div slot="main-content">
            <div class="admin-page-title">
                <small>ORDERS</small>
            </div>

            <div class="card">
                <div class="card-body">
                    <h6 class="mb-4">Transactions</h6>

                    <div class="table-responsive">
                        <table class="table" width="100%">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">Trasaction ID</th>
                                    <th scope="col">Billing Name</th>
                                    <th scope="col">address</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Payment Status</th>
                                    <th scope="col">View details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(order, index) in orders" :key="index">
                                    <td>
                                        <b>{{order.trnxref}}</b>
                                    </td>
                                    <td>
                                        {{order.name}} <br>
                                        <small>{{order.email}}</small>
                                    </td>
                                    <td>
                                        <small>{{order.address}}, <b>{{order.city}}</b> </small>
                                    </td>
                                  <td>{{ new Date(order.timestamp).toLocaleDateString() }}</td>
                                    <td><small class="p-1 px-3 alert alert-primary alert-rounded">paid</small></td>
                                    <td><small>USD</small> {{order.amount / 100}}</td>
                                    <td>
                                        <button class="btn btn-success btn-details" @click="orderDetails(order)">View</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <modal v-if="showModal" @close="showModal = false; order = null" size="500px">
                <!--
                you can use custom content here to overwrite
                default content
                -->
                <h6 slot="header">Transaction #{{order.trnxref}}</h6>

                <div slot="body">
                    <div>
                        <div>
                            <small><b>{{order.name}}</b></small> <br>
                            <small>{{order.phone}}</small> <br>
                            <small>{{order.email}}</small> <br>
                            <small>{{order.address}}, {{order.city}}</small>
                        </div>

                        <div class="my-3">
                            <a :href="order.details[0].story.pdfdoc" target="_blank" download="Story"><small class="text-primary text-bold"><span class="ti-download"></span> PDF Document</small></a>
                        </div>

                        <div class="table-responsive">
                            <table class="table mt-4" width="100%">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col"><small>Cover</small></th>
                                        <th scope="col"><small>Child</small></th>
                                        <th scope="col"><small>Story</small></th>
                                        <th scope="col"><small>Custom message</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <img :src="order.details[0].cover" width="30px" height="30px" class="rounded" alt="">
                                        </td>
                                        <td><small>{{order.details[0].childname}}</small></td>
                                        <td><small>{{order.details[0].story.title}}</small></td>
                                        <td><small>{{order.details[0].personalMsg}}</small></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </modal>
        </div>
    </dashboard-main>
</template>

<script>
import DashboardMain from "@/components/layouts/DashboardMain"
import Modal from "@/components/Modal"

export default {
    name: "Orders",
    components: {
        DashboardMain,
        Modal
    },
    data() {
        return {
            orders: [],
            order: null,
            showModal: false
        }
    },
    mounted() {
        console.log(localStorage.token)
        this.$store.commit('setToken')
        this.getOrders()
    },
    methods: {
        async getOrders() {
            await this.$axios.get(`${this.$apiurl}/order/`, {
                headers: {
                    Authorization: `Token ${localStorage.token}`
                }
            })
            .then(res => {
                const orders = res.data.map(item => {
                    item.details = JSON.parse(item.details)
                    return item
                })
                this.orders = orders
            })
            .catch(error => {
                console.log(error.response)
            })
        },
        orderDetails(order) {
            if(order !== null) {
                this.order = order
                this.showModal = true
            }
        }
    },
    computed: {
        
    }
}
</script>