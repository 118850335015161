<template>
    <main>
        
        <section class="page-header py-4 bg-grey">
            <div class="container">
                <h1>Gallery</h1>
                <p>Photo collection</p>
            </div>
        </section>

        <section class="gallery-wrapper py-4 bg-grey">
            <div class="container">
                <div class="blog-articles">
                    <div class="article gallery-item" 
                        :style="`background: url(${image.url})`" 
                        v-for="(image, index) in gallery" 
                        :key="index"
                        :data-index="index"
                    >
                        <div class="article-overlay" @click="open($event)" :src="image.url"></div>
                    </div>
                </div>

                <div class="blog-pagination mt-5">
                    <a href="" class="active">1</a>
                    <a href="" class="">2</a>
                    <a href="" class="">Next <span class="ti-arrow-right"></span></a>
                </div>
                
            </div>
        </section>

        <Footer />
    </main>
</template>

<script>
import fancyBox from 'vue-fancybox';
import Footer from '@/components/Footer'

export default {
    name: 'Gallery',
    components: {
        Footer,
    },
    data() {
       return {
           gallery: [
                { width: 3187, height: 4780, url: require('@/assets/images/gallery/3.jpg'), },
                { width: 3773, height: 5660, url:  require('@/assets/images/gallery/4.jpg'), },
                { width: 2025, height: 2025, url: require('@/assets/images/gallery/2.jpg'), },
                { width: 3896, height: 5843, url: require('@/assets/images/gallery/1.jpg') },
           ],
       } 
    },
    methods: {
        open (e) {
            fancyBox(e.target, this.gallery);
        }
    }
}
</script>