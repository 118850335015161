<template>
    <main>
        
        <section class="page-header py-4 bg-grey">
            <div class="container">
                <h2 class="mb-0">Stories</h2>
                <small>Our stories collection</small>
            </div>
        </section>

        <section class="py-4 bg-grey">
            <div class="container" style="min-height: 40vh">
                <div class="stories-grid">
                    <div class="story-card card" v-for="(story, index) in stories" :key="index">
                        <div class="book-img rounded mb-3" :style="`background-image: url(${story.cover})`"></div>
                        <h5>{{story.title}}</h5>
                        <div v-html="story.description" class="story-desc"></div>

                        <div class="price">
                            <small><b></b></small>
                            <span class="badge badge-primary px-2 py-2" @click="$router.push(`story/${story.id}`)">See details</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />
    </main>
</template>

<script>
import Footer from '@/components/Footer'

export default {
    name: "Stories",
    components: {
        Footer
    },
    data() {
        return {
            stories: []
        }
    },
    mounted() {
        this.get()
    },
    methods: {
        get() {
            this.$axios.get(`${this.$apiurl}/stories/`)
            .then(res => {
                this.stories = res.data
            })
            .catch(error => {
                console.log(error.response)
            })
        },
        setStory(id) {
            localStorage.story = id
            this.$router.push('/create-your-book')
        }
    }
}
</script>