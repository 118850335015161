import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const url = `https://tribal-books.herokuapp.com`

const store = new Vuex.Store({
    state: {
        loader: false,
        books: [],
        stories: [],
        testimonies: undefined,
        cart: [],
        token: '',
        isLoading: false,
    },
    getters: {
        getBooks: (state) => {
            return state.books
        },
        getStoriesIndex: (state) => {
            return state.stories.splice(0, 4)
        },
        testimonies: (state) => {
            return state.testimonies
        },
        getCart: (state) => {
            const cart = localStorage.getItem('cart')
            if(state.cart.length > 1) return state.cart

            if(cart && JSON.parse(cart)) return JSON.parse(cart)

            return []
        },
    },
    mutations: {
        updateLoader: (state, payload) => {
            state.isLoading = payload
        },
        setToken: (state, payload) => {
            state.token = payload || localStorage.token
        },
        setBooks: (state, payload) => {
            state.books = payload
        },
        setStories: (state, payload) => {
            state.stories = payload
        },
        setTestimonies: (state, payload) => {
            state.testimonies = payload
        },
        updateCart: (state, payload) => {
            let cartDB = localStorage.getItem('cart')
            if(!cartDB || JSON.parse(cartDB).length < 1) {
                let cart = [payload]
                localStorage.setItem('cart', JSON.stringify(cart))
                state.cart.push(payload)
                return
            }
            
            let cart = JSON.parse(cartDB)
            
            let isFound = false
            /*cart.map(item => {
                if(item.id === payload.id) {
                    isFound = true
                    return item.qty += 1;
                }
            })*/

            if(!isFound) cart.push(payload)
            state.cart = cart
            localStorage.setItem('cart', JSON.stringify(cart))
        },
        dropFromCart: (state, payload) => {
            let cartDB = localStorage.getItem('cart')
            if(cartDB && JSON.parse(cartDB).length > 0) {
                let cart = JSON.parse(cartDB)
                //const itemIndex = cart.findIndex(item => item.id === payload)
                cart.splice(payload, 1)
                localStorage.setItem('cart', JSON.stringify(cart))
                state.cart = cart
            }
        },
        emptyCart: (state) => {
            let cart = []
            localStorage.setItem('cart', JSON.stringify(cart))
            state.cart = []
        }
    },
    actions: {
        getBooks: (context) => {
            axios.get(`${url}/book/`)
            .then(res => {
                context.commit('setBooks', res.data);
            })
            .catch(err => { console.log(err) });
        },
        getStories: (context) => {
            axios.get(`${url}/stories/`)
            .then(res => {
                context.commit('setStories', res.data);
            })
            .catch(err => { console.log(err) });
        },
        getTestimonies: (context) => {
            axios.get(`${url}/testimonies/`)
            .then(res => {
                context.commit('setTestimonies', res.data);
            })
            .catch(err => { console.log(err) });
        },
        emptyCart: (context) => {
            context.commit('emptyCart')
        }
    }
})

export default store