<template>
    <div>
        <header :class="bgtype">
            <div class="container">
                <nav>
                    <h4 id="brand"><router-link to="/">Black Baby</router-link></h4>
                    
                    <ul>
                        <li><router-link to="/about">About</router-link></li>
                        <li><router-link to="/stories">Stories</router-link></li>
                        <li><router-link to="/contact">Contact</router-link></li>
                        <li><a href="https://www.amazon.com/s?i=stripbooks&rh=p_27%3AUgo+Arthur+Ezeoke&s=relevancerank&text=Ugo+Arthur+Ezeoke&ref=dp_byline_sr_book_1" target="_blank">Store</a></li>
                        <li><router-link to="signin">Login</router-link></li>
                    </ul>

                    <div>
                        <router-link to="/create-your-book" class="btn btn-primary mr-4">Create</router-link>
                        
                        <span @click="displayCart()"><span class="ti-shopping-cart"></span><span>{{cartCount}}</span></span>
                    </div>
                </nav>
            </div>
        </header>

        <div class="cart-wrapper" :class="showCart? 'show-cart' : ''">
            <div class="p-2 px-3 cart-slide-header">
                <h5 class="mb-0">Your Cart</h5>
                <button @click="displayCart()"><span class="ti-close"></span></button>
            </div>
            <hr>
            <div class="p-2 px-3">
                <div class="cart-slide-grid" v-for="(item, index) in cartItems" :key="index">
                    <div>
                        <p class="mb-0">{{item.story.title}}</p>
                        <p>{{item.childname}}</p>
                        <small class="" @click="dropItem(index)">Remove</small>
                    </div>
                    <div>
                        USD{{item.story.price}}
                    </div>
                </div>
            </div>
            <hr>
            <div class="p-2 px-3">
                <div class="cart-slide-grid">
                    <h6>Subtotal</h6>
                    <span>USD{{cartTotal}}</span>
                </div>
                <button class="btn btn-block btn-primary" @click="showCart = false; $router.push('/checkout')">Checkout</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Nav',
    props: [
        'bgtype'
    ],
    data() {
        return {
            showCart: false,
        }
    },
    mounted() {
        
    },
    methods: {
        displayCart() {
            this.showCart = !this.showCart
        },
        dropItem(id) {
            this.$store.commit('dropFromCart', id)
        }
    },
    computed: {
        cartItems() {
            let cart = this.$store.getters.getCart
            return cart
        },
        cartCount() {
            let cart = this.$store.getters.getCart
            return cart.length
        },
        cartTotal() {
            let cart = this.$store.getters.getCart
            let total = 0

            cart.map(item => {
                total += Number(item.story.price)
            })
            return total
        }
    }
}
</script>