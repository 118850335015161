<template>
    <dashboard-main>
        <div slot="main-content">
            <div class="admin-page-title">
                <small>CUSTOMERS</small>
            </div>

            <div class="card">
                <div class="card-body">
                    <h6 class="mb-4">Latest Transaction</h6>

                    <div class="table-responsive">
                        <table class="table" width="100%">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">Order ID</th>
                                    <th scope="col">Billing Name</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Payment Status</th>
                                    <th scope="col">Payment Method</th>
                                    <th scope="col">View details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <b>#SK2540</b>
                                    </td>
                                    <td>
                                        Neal Mathews
                                    </td>
                                    <td>07 Oct, 2020</td>
                                    <td><small class="p-1 px-3 alert alert-primary alert-rounded">paid</small></td>
                                    <td>$400</td>
                                    <td><span class="ti-card"></span> Mastercard</td>
                                    <td>
                                        <button class="btn btn-success btn-details">View</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>#SK2540</b>
                                    </td>
                                    <td>
                                        Neal Mathews
                                    </td>
                                    <td>07 Oct, 2020</td>
                                    <td><small class="p-1 px-3 alert alert-danger alert-rounded">charge back</small></td>
                                    <td>$400</td>
                                    <td><span class="ti-card"></span> Mastercard</td>
                                    <td>
                                        <button class="btn btn-success btn-details">View</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>#SK2540</b>
                                    </td>
                                    <td>
                                        Neal Mathews
                                    </td>
                                    <td>07 Oct, 2020</td>
                                    <td><small class="p-1 px-3 alert alert-success alert-rounded">paid</small></td>
                                    <td>$400</td>
                                    <td><span class="ti-card"></span> Mastercard</td>
                                    <td>
                                        <button class="btn btn-success btn-details">View</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>#SK2540</b>
                                    </td>
                                    <td>
                                        Neal Mathews
                                    </td>
                                    <td>07 Oct, 2020</td>
                                    <td><small class="p-1 px-3 alert alert-info alert-rounded">completed</small></td>
                                    <td>$400</td>
                                    <td><span class="ti-card"></span> Mastercard</td>
                                    <td>
                                        <button class="btn btn-success btn-details">View</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </dashboard-main>
</template>

<script>
import DashboardMain from "@/components/layouts/DashboardMain"

export default {
    name: "Customers",
    components: {
        DashboardMain
    }
}
</script>