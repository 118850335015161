<template>
    <main>
        
        <section class="page-header py-5 bg-grey">
            <div class="container">
                <h1 class="contact-header">About Us</h1>
            </div>
        </section>

        <section class="bg-grey">
            <div class="container py-1">
                <div class="row pb-5">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <p>Black Baby World believe in making children the stars of their own stories, and sending them on unforgettable adventures around the world. With Black Baby World you can personalise your book way beyond simply adding your child’s name, making our book one of the most personalised products in the publishing world.</p>
                            </div>
                        </div>

                        <div class="my-5">
                            <div class="text-center">
                                <h5 class="mb-4">Star in your own personal adventure!</h5>
                                <router-link to="/create-your-book" class="btn btn-primary">Create your Book</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />
    </main>
</template>

<script>
import Footer from '@/components/Footer'

export default {
    name: 'About',
    components: {
        Footer,
    },
    data() {
       return {
           
       } 
    },
}
</script>