<template>
    <main>
        
        <section class="page-header py-5 bg-grey">
            <div class="container">
                <h1 class="contact-header">How can we help you out?</h1>
            </div>
        </section>

        <section class="bg-grey">
            <div class="container py-4">
                <div class="row pb-4 contact-cards">
                    <!--<div class="col-md-3 contact-toon">
                        <img :src="require('@/assets/images/compiled/woman-girl/8.png')" width="100%" alt="">
                    </div>-->
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">

                                <div class="address-block">
                                    <h6>General Enquiries</h6>
                                    <p class="text-link">awesomeblackchild@gmail.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="address-block">
                                    <h6>Contact number</h6>
                                    <a href="tel:+14234440370">
                                        +14234440370
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />
    </main>
</template>

<script>
import Footer from '@/components/Footer'

export default {
    name: 'Contact',
    components: {
        Footer,
    },
    data() {
       return {
           
       } 
    },
}
</script>