<template>
    <div class="card">
        <div class="card-body">
            <h6 class="mb-4">Story details</h6>
            <form action="" @submit.prevent="update($event)" enctype="multipart/form-data">
                <div class="row">
                    <div class="col-md-6 col-12">
                            <div class="form-group">
                            <label for="">Title</label>
                            <input type="text" name="title" v-model="story.title" class="form-control" placeholder="Story title">
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                            <div class="form-group">
                            <label for="">Author</label>
                            <input type="text" name="author" class="form-control" v-model="story.author" placeholder="Story author">
                        </div>
                    </div>
                    <div class="col-md-12 col-12">
                            <div class="form-group">
                            <label for="">Description</label>
                            <div style="width: 100%">
                                <wysiwyg v-model="story.description" />
                                <!--<textarea name="description" v-model="book.description" class="form-control" placeholder="Story description"></textarea>-->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                            <div class="form-group">
                            <label for="">Price</label>
                            <input type="number" name="price" v-model="story.price" class="form-control" >
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                            <div class="form-group">
                            <label for="">Upload cover</label>
                            <input type="file" name="cover" class="form-control" >
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                            <div class="form-group">
                            <label for="">Upload preview pdf</label>
                            <input type="file" name="preview" class="form-control" >
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                            <div class="form-group">
                            <label for="">Upload book</label>
                            <input type="file" name="pdfdoc" class="form-control" >
                        </div>
                    </div>
                </div>
                <div class="form-group mt-3 text-right">
                    <button type="submit" class="btn btn-primary">Update story</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: ['story'],
    mounted() {
        this.$store.commit('setToken')
    },
    methods: {
        update(event) {
            const { id, title, author, description, price } = this.story
            let form = event.srcElement
            const formdata = new FormData(form)

            if(!formdata.get('cover').name) formdata.delete('cover')
            if(!formdata.get('preview').name) formdata.delete('preview')
            if(!formdata.get('pdfdoc').name) formdata.delete('pdfdoc')

            if(!title || !author || !description || !price || Number(price) < 1) {
                return
            }

            formdata.append('description', description)
            
            this.$axios.patch(`${this.$apiurl}/stories/${id}/`, formdata, {
                headers: {
                    Authorization: `Token ${this.token}`
                }
            })
            .then(() => {
                this.story.description = this.story.author = this.story.title = ''
                form.reset()
                this.$emit('close')
                //console.log(res.data)
            })
            .catch(error => {
                console.error(error)
                console.log(error.response)
            })
        }
    },
    computed: {
        ...mapState([
            'token'
        ])
    }
}
</script>