<template>
    <main>
        
        <section class="blog-header bg-main py-4">
            <div class="container">
                <h1>Our Blog</h1>
                <p>Share your stories with everyone</p>
            </div>
        </section>

        <section class="blog-articles-wrapper bg-main py-4">
            <div class="container">
                <div class="blog-articles">
                    <div class="article">
                        <article>
                            <div>
                                <div class="article-author">
                                    <img :src="require('@/assets/images/avatar.jpg')" alt="">
                                    <div class="article-date">
                                        <p>Posted by Techadam</p>
                                        <p>September 16, 2020</p>
                                    </div>
                                </div>

                                <div class="article-body">
                                    <h4>Why Reading Is So Important For Children</h4>
                                    <p>We all know that reading is beneficial for children, but what are the reasons why? Find out in our blog.</p>
                                </div>
                            </div>

                            <a href="">Read more <span class="ti-arrow-right"></span></a>
                        </article>
                    </div>
                    <div class="article">
                        <article>
                            <div>
                                <div class="article-author">
                                    <img :src="require('@/assets/images/avatar.jpg')" alt="">
                                    <div class="article-date">
                                        <p>Posted by Techadam</p>
                                        <p>September 16, 2020</p>
                                    </div>
                                </div>

                                <div class="article-body">
                                    <h4>Why Reading Is So Important For Children</h4>
                                    <p>We all know that reading is beneficial for children, but what are the reasons why? Find out in our blog.</p>
                                </div>
                            </div>

                            <a href="">Read more <span class="ti-arrow-right"></span></a>
                        </article>
                    </div>
                    <div class="article">
                        <article>
                            <div>
                                <div class="article-author">
                                    <img :src="require('@/assets/images/avatar.jpg')" alt="">
                                    <div class="article-date">
                                        <p>Posted by Techadam</p>
                                        <p>September 16, 2020</p>
                                    </div>
                                </div>

                                <div class="article-body">
                                    <h4>Why Reading Is So Important For Children</h4>
                                    <p>We all know that reading is beneficial for children, but what are the reasons why? Find out in our blog.</p>
                                </div>
                            </div>

                            <a href="">Read more <span class="ti-arrow-right"></span></a>
                        </article>
                    </div>
                    <div class="article">
                        <article>
                            <div>
                                <div class="article-author">
                                    <img :src="require('@/assets/images/avatar.jpg')" alt="">
                                    <div class="article-date">
                                        <p>Posted by Techadam</p>
                                        <p>September 16, 2020</p>
                                    </div>
                                </div>

                                <div class="article-body">
                                    <h4>Why Reading Is So Important For Children</h4>
                                    <p>We all know that reading is beneficial for children, but what are the reasons why? Find out in our blog.</p>
                                </div>
                            </div>

                            <a href="">Read more <span class="ti-arrow-right"></span></a>
                        </article>
                    </div>
                </div>

                <div class="blog-pagination mt-5">
                    <a href="" class="active">1</a>
                    <a href="" class="">2</a>
                    <a href="" class="">Next <span class="ti-arrow-right"></span></a>
                </div>
                
            </div>
        </section>

        <Footer />
    </main>
</template>

<script>
import Footer from '@/components/Footer'

export default {
    name: 'Blog',
    components: {
        Footer
    },
    data() {
       return {
           
       } 
    }
}
</script>