<template>
    <main>
        
        <section class="bg-grey">
            <div class="container py-2">
                <small><router-link to="/">Home</router-link><span class="ti-angle-right px-2"></span><span class="text-muted">{{story.title}}</span></small>
            </div>
        </section>


        <section class="">
            <div class="container py-4">
                <div class="row">
                    <div class="col-md-3">
                        <img :src="story.cover" width="100%" alt="" class="mb-4">
                    </div>
                    <div class="col-md-9">
                        <h3>{{story.title}}</h3>
                        <p>by <b class="text-primary text-bold">{{story.author}}</b></p>

                        <div class="book-action-btn">
                            <button @click="preview(story.preview)" type="button" class="btn btn-primary mr-3"><span class="ti-eye text-white"></span> See preview</button>
                            <button type="button" @click="setStory(story.id)" class="btn btn-outline-primary"><span class="ti-control-forward"></span> Select story</button>
                        </div>
                        
                        <div class="book-describe mt-4" v-html="story.description"></div>
                    </div>
                </div>
            </div>
        </section>
        <hr>
        <Footer />
        
        <modal v-if="selectedPdf !== null" @close="selectedPdf = null" size="800px">
            
            <div slot="header">
                <div class="modal-slot-head">
                    <h5>Update story</h5>
                    <div class="pdf-nav">
                        <button class="btn-primary" @click="currentPage > 1? currentPage-- : currentPage"><span class="ti-angle-left"></span></button>
                        <button class="btn-primary" @click="currentPage++"><span class="ti-angle-right"></span></button>
                    </div>
                </div>
            </div>

            <div slot="body">
                <pdf :src="selectedPdf"
                    :page="currentPage"
                ></pdf>
            </div>
        </modal>
    </main>
</template>

<script>
import Footer from '@/components/Footer'
import Modal from '@/components/Modal'
import pdf from 'vue-pdf'
import { mapState } from 'vuex'

export default {
    name: 'BookDetail',
    components: {
        Footer,
        Modal,
        pdf,
    },
    data() {
        return {
            story: {},
            selectedPdf: null,
            currentPage: 1,
        }
    },
    mounted() {
        this.get()
    },
    methods: {
        get() {
            if(!this.$route.params.id) {
                this.$router.push('/stories')
            }

            this.$axios.get(`${this.$apiurl}/stories/${this.$route.params.id}/`)
            .then(res => {
                this.story = res.data
            })
            .catch(error => {
                console.log(error)
            })
        },
        preview(pdf) {
            this.selectedPdf = pdf
        },
        setStory(id) {
            localStorage.story = id
            this.$router.push('/create-your-book')
        },
        addToCart() {
            let {id, title, author, base_cover_design} = this.story
            
            this.$store.commit('updateCart', {
                id,
                title, 
                author, 
                base_cover_design,
                qty: 1,
            })
        }
    },
    computed: {
        ...mapState([
            'cart'
        ])
    }
}
</script>