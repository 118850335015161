<template>
    <main>
        <section class="hero">
            <div class="container py-5">
                <div class="row py-5">
                    <div class="col-md-6 col-12 pr-3 hero-text" data-aos="fade-up-right">
                        <h1 class="text-grey">Create a personalized book in a few steps</h1>
                        <p><b><span>Want to know more - <a href="#video-tutorial" class="text-grey">watch our video</a>, or get started below</span></b> </p>

                        <div class="mt-4">
                            <router-link to="/create-your-book" class="btn btn-primary">Create your book</router-link>
                        </div>
                    </div>
                    <div class="col-md-6 hero-img" data-aos="fade-left">
                        <img :src="require('@/assets/images/child-2.png')" width="100%" alt="">
                    </div>
                </div>
            </div>
        </section>

        <section class="steps bg-grey pb-5">
            <div class="container py-5">
                <div class="row justify-content-center">
                    <div class="col-md-7 col-12">
                        <h3 class="text-center section-header">Create your own personalised children's book in 4 simple steps!</h3>
                    </div>
                </div>

                <!--<div class="grid-row mt-5">
                    <div class="grid-col-3" data-aos="fade-right">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="step-head">01</h4>
                                <p>Start with your child's name</p>
                            </div>
                        </div>
                    </div>
                    <div class="grid-col-3" data-aos="zoom-in">
                        <div class="card bg-main">
                            <div class="card-body">
                                <h4 class="step-head">02</h4>
                                <p>Create their personalised cartoon character</p>
                            </div>
                        </div>
                    </div>
                    <div class="grid-col-3" data-aos="zoom-in">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="step-head">03</h4>
                                <p>Choose which animal will join the adventure</p>
                            </div>
                        </div>
                    </div>
                    <div class="grid-col-3" data-aos="fade-right">
                        <div class="card">
                            <div class="card-body bg-main">
                                <h4 class="step-head">04</h4>
                                <p>Add a personalised message and purchase securely</p>
                            </div>
                        </div>
                    </div>
                </div>-->

                <div class="row justify-content-center mt-5">
                    <div class="col-md-10 col-12">
                        <div class="row align-items-center mb-5">
                            <div class="col-md-6 col-6" data-aos="fade-right">
                                <div class="bg-white p-5 rounded step-card">
                                    <h3 class="step-head">01</h3>
                                    <h3><router-link to="/create-your-book" tag="span" class="text-primary">Start with</router-link> your child's name</h3>
                                </div>
                            </div>
                            <div class="col-md-6 col-6" data-aos="fade-left">
                                <img :src="require('@/assets/images/compiled/man-boy/3.png')" width="100%" alt="">
                            </div>
                        </div>
                        <div class="row align-items-center mb-5">
                            <div class="col-md-6 col-6" data-aos="fade-right">
                                <img :src="require('@/assets/images/compiled/woman-girl/2.png')" width="100%" alt="">
                            </div>
                            <div class="col-md-6 col-6" data-aos="fade-left">
                                <div class="bg-white p-5 rounded step-card">
                                    <h3 class="step-head">02</h3>
                                    <h3><router-link to="/create-your-book" tag="span"  class="text-primary">Create their</router-link> personalised cartoon character</h3>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center mb-5">
                            <div class="col-md-6 col-6" data-aos="fade-right">
                                <div class="bg-white p-5 rounded step-card">
                                    <h3 class="step-head">03</h3>
                                    <h3><router-link to="/create-your-book" tag="span" class="text-primary">Choose which</router-link> animal will join the adventure</h3>
                                </div>
                            </div>
                            <div class="col-md-6 col-6" data-aos="fade-left">
                                <img :src="require('@/assets/images/compiled/woman-boy/7.png')" width="100%" alt="">
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-md-6 col-6" data-aos="fade-right">
                                <img :src="require('@/assets/images/compiled/man-girl/8.png')" width="100%" alt="">
                            </div>
                            <div class="col-md-6 col-6" data-aos="fade-left">
                                <div class="bg-white p-5 rounded step-card">
                                    <h3 class="step-head">04</h3>
                                    <h3><router-link to="/create-your-book" tag="span"  class="text-primary">Add a personalised</router-link> message and purchase securely</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row justify-content-center" id="video-tutorial">
                    <div class="col-md-8 col-12">
                        <h4 class="text-bold mt-5 text-center section-header">Watch Video to follow steps</h4>
                        <div data-aos="flip-up" data-aos-delay="200">
                            <div class="video-bg rounded">
                                <div class="video-overlay">
                                    <div class="video-play-btn">
                                        <span class="ti-control-play"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pb-3">
            <div class="container py-5">
                <div class="row justify-content-center">
                    <div class="col-md-7">
                        <h2 class="text-center section-header">Find out what children love about Black Baby World here</h2>
                    </div>
                </div>

                <div class="book-grid">
                    <div class="sub-book-grid">
                        <!--<div class="book-card" data-aos="fade-up" :data-aos-delay="50 + index" v-for="(book, index) in books" :key="index">
                            <div class="book-img rounded" :style="`background-image: url('${book.base_cover_design}')`"></div>
                            <div class="book-info">
                                <div><p><router-link :to="`/book/${book.id}`" class="book-title">{{book.title}}</router-link></p></div>
                                <div class="book-icons">
                                    <span><span class="ti-comment-alt"></span> 12</span>
                                    <span><span class="ti-heart"></span> 12</span>
                                </div>
                            </div>
                        </div>-->

                        <div class="book-card" data-aos="fade-up" data-aos-delay="50" v-for="(book, index) in getStoriesIndex" :key="index">
                            <div class="book-img rounded" :style="`background-image: url(${book.cover})`"></div>
                            <div class="book-info">
                                <div><p><router-link to="/"><b>{{book.title}}</b></router-link></p></div>
                                <div class="book-icons">
                                    <router-link :to="`/story/${book.id}`" class="btn btn-primary">Preview <span class="ml-2 ti-arrow-right text-white"></span></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="mt-5" v-if="testimonies">
            <div class="slider-wrapper">
                <div class="slider-container rounded">
                  <VueSlickCarousel :arrows="true" :dots="true" >
                    <div class="carousel-slick" v-for="i in 3" :key="i">
                      <div class="carousel-avatar">
                        <img :src="testimonies[i - 1] ? testimonies[i - 1].avatar : ''" class="rounded-circle" style="object-fit: cover" width="70px" height="70px" alt="">
                      </div>
                      <div class="carousel-info">
                        <h1><span class="ti-quote-right text-secondary"></span></h1>
                        <p>{{ testimonies[i - 1]  ? testimonies[i -1].testimony : '' }}</p>

                        <h3 class="text-uppercase text-bold text-dark">{{ testimonies[i - 1] ? testimonies[i - 1].name : '' }}</h3>
                        <span class="text-muted">{{ testimonies[i - 1] ? testimonies[i - 1].title : '' }}</span>
                      </div>
                    </div>
                  </VueSlickCarousel>
                </div>
            </div>
        </section>

        <Footer />
    </main>
</template>

<script>
import { mapGetters } from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import Footer from '@/components/Footer'

export default {
    name: 'Home',
    components: {
        VueSlickCarousel,
        Footer
    },
    data() {
       return {

       }
    },
    mounted() {
        this.$store.dispatch('getStories')
        this.$store.dispatch('getTestimonies')
    },
    computed: {
        ...mapGetters([
            'getStoriesIndex', 'testimonies'
        ]),
    }
}
</script>