<template>
  <main>
    <section class="page-header py-4 bg-grey">
      <div class="container">
        <h2>Create your book</h2>
      </div>
    </section>

    <section class="py-4 bg-grey">
      <div class="container">
        <div class="create-wrapper-grid">

          <div class="create-input-wrapper pr-5">

            <div class="option-block mb-5">
              <div class="row">
                <div class="col-md-6 col-12">
                  <h5 class="mb-3"><span>1.</span> <span>Type child name</span></h5>
                  <div class="form-group">
                    <input type="text" class="form-control" v-model="cartData.childname" placeholder="Child name">
                  </div>
                </div>

                <div class="col-md-6 col-12">
                  <h5 class="mb-3"><span>2.</span> <span>Select story</span></h5>
                  <div class="form-group">
                    <select type="text" v-model="cartData.story" class="form-control">
                      <option value="">Choose</option>
                      <option :value="story.id" v-for="(story, index) in stories" :key="index">{{ story.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="option-block mb-5">
              <h5 class="mb-3"><span>3.</span> <span>Select Adult(s) Character</span></h5>

              <div class="flex-option">
                <input type="radio" v-model="imageUrlParams.parent_sex" name="parent" id="parent" value="mum">
                <label class="bg-white" for="parent">
                  <div>
                    <img src="@/assets/images/mom.png" width="40px" alt="">
                    <span>Mom</span>
                  </div>
                </label>

                <input type="radio" id="parent1" v-model="imageUrlParams.parent_sex" name="parent" value="dad">
                <label class="bg-white" for="parent1">
                  <div>
                    <img src="@/assets/images/dad.svg" width="40px" alt="">
                    <span>Dad</span>
                  </div>
                </label>

                <input type="radio" id="parent3" v-model="imageUrlParams.parent_sex" name="parent" value="uncle">
                <label class="bg-white" for="parent3">
                  <div>
                    <img src="@/assets/images/dad.svg" width="40px" alt="">
                    <span>Uncle</span>
                  </div>
                </label>

                <input type="radio" id="parent4" v-model="imageUrlParams.parent_sex" name="parent" value="aunty">
                <label class="bg-white" for="parent4">
                  <div>
                    <img src="@/assets/images/mom.png" width="40px" alt="">
                    <span>Aunty</span>
                  </div>
                </label>

                <input type="radio" id="parent5" v-model="imageUrlParams.parent_sex" name="parent" value="mom_and_dad">
                <label class="bg-white" for="parent5">
                  <div>
                    <img src="@/assets/images/dad.svg" width="40px" alt="">
                    <span>Mom and Dad</span>
                  </div>
                </label>

                <input type="radio" id="parent6" v-model="imageUrlParams.parent_sex" name="parent"
                       value="aunty_and_uncle">
                <label class="bg-white" for="parent6">
                  <div>
                    <img src="@/assets/images/dad.svg" width="40px" alt="">
                    <span>Uncle and Aunty</span>
                  </div>
                </label>
              </div>
            </div>

            <div class="option-block mb-5">
              <h5 class="mb-3"><span>4.</span> <span>Select child gender</span></h5>

              <div class="flex-option">
                <input type="radio" v-model="imageUrlParams.child_sex" name="child" id="child" value="girl">
                <label class="bg-white" for="child">
                  <div>
                    <!--<img src="@/assets/images/mom.png" width="40px" alt="">-->
                    <div><span class="ti-user mr-3 py-2"></span></div>
                    <span>Female</span>
                  </div>
                </label>

                <input type="radio" id="child1" v-model="imageUrlParams.child_sex" name="child" value="boy">
                <label class="bg-white" for="child1">
                  <div>
                    <!--<img src="@/assets/images/dad.svg" width="40px" alt="">-->
                    <div><span class="ti-user mr-3 py-2"></span></div>
                    <span>Male</span>
                  </div>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="option-block mb-5">
                  <h5 class="mb-3"><span>5.</span> <span>Glasses?</span></h5>

                  <div class="flex-option">
                    <switches v-model="imageUrlParams.glasses" theme="bootstrap" color="primary" text-enabled="ON"
                              text-disabled="OFF" type-bold="true"></switches>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="option-block mb-5">
                  <h5 class="mb-3"><span>6.</span> <span>Facial hair?</span></h5>

                  <div class="flex-option">
                    <switches v-model="imageUrlParams.facial_hair" theme="bootstrap" color="primary" text-enabled="ON"
                              text-disabled="OFF" type-bold="true"></switches>
                  </div>
                </div>
              </div>
            </div>

            <div class="option-block mb-4">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h5 class="mb-3"><span>7.</span> <span>Add personalized message</span></h5>
                  <div class="form-group">
                    <textarea type="text" v-model="cartData.personalMsg" class="form-control"
                              placeholder="Personalized message"></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="option-block">
              <button type="button" class="px-5 btn btn-primary" @click="getImages('modal')">Submit entries</button>
            </div>
            <!--<ik-image
                path="/tribalbooks/1_ELGh570of4E.png"
            />-->
          </div>

          <div class="create-photo-loader">
            <img :src="imageKit(image, 400)" alt="" v-if="images.length > 0">
          </div>
        </div>


        <modal v-if="showModal" @close="showModal = false" size="800px">
          <!--
          you can use custom content here to overwrite
          default content
          -->
          <h5 slot="header">Choose your book cover</h5>

          <div slot="body">
            <div class="row">
              <div class="col-md-3 mb-3" v-for="(image, index) in images" :key="index" @click="addToCart(image)"
                   style="cursor: pointer">
                <img :src="imageKit(image.url, 150)" width="100%" alt=""/>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </section>

    <Footer/>
  </main>
</template>

<script>
import Footer from '@/components/Footer'
import Switches from 'vue-switches'
import Modal from "@/components/Modal"

export default {
  name: 'Create',
  components: {
    Footer,
    Switches,
    Modal
  },
  data() {
    return {
      covers: [],
      stories: [],
      imageUrlParams: {
        child_sex: '',
        parent_sex: '',
        facial_hair: false,
        glasses: false
      },
      images: [],
      showModal: false,
      cartData: {
        childname: '',
        story: localStorage.story || '',
        personalMsg: '',
        cover: '',
        price: 20.00
      }
    }
  },
  mounted() {
    this.getCovers()
    this.getStories()
  },
  computed: {
    image() {
      if (this.images.length === 0) {
        return undefined
      }
      const index = Math.floor(Math.random() * this.images.length)
      return this.images[index].url
    },
    imageKit() {
      return (imageUrl, size = 240) => {
        if (!imageUrl) {
          return ""
        }
        const parts = imageUrl.split("/")
        const insertPosition = parts.length - 1
        const imageKitProp = `tr:w-${size}`
        parts.splice(insertPosition, 0, imageKitProp)
        return parts.join("/")
      }
    }
  },
  methods: {
    getCovers() {
      this.$axios.get(`${this.$apiurl}/book_cover/`)
          .then(res => {
            this.covers = res.data
          })
          .catch(error => {
            console.error(error)
            console.log(error.response)
          })
    },
    getStories() {
      this.$axios.get(`${this.$apiurl}/stories/`)
          .then(res => {
            this.stories = res.data

          })
          .catch(error => {
            console.error(error)
            console.log(error.response)
          })
    },
    getImages(isModal = '') {
      const { child_sex, parent_sex, facial_hair, glasses } = this.imageUrlParams
      const cover = this.covers.find(book_cover => {
        if (child_sex === book_cover.child_sex && parent_sex === book_cover.parent_sex && facial_hair === book_cover.facial_hair && glasses === book_cover.glasses) {
          return book_cover
        }
      })

      if (cover) {
        const formdata = new FormData()
        formdata.append('images_folder_url', cover.images_to_load)

        this.$store.commit('updateLoader', true)

        this.$axios.post(`${this.$apiurl}/book_cover/images_to_load`, formdata)
            .then(res => {
              this.$store.commit('updateLoader', false)

              this.images = res.data.response
              this.showModal = isModal ? true : false
            })
            .catch(error => {
              this.$store.commit('updateLoader', false)
              console.log(error.response)
            })
      }
    },
    addToCart(image) {
      this.cartData.cover = image.url
      const story = this.stories.find(story => story.id === Number(this.cartData.story))
      this.cartData.story = story
      this.$store.commit('updateCart', this.cartData)
      this.cardData = {
        childname: '',
        story: '',
        personalMsg: '',
        cover: '',
        price: 20.00,
      }
      this.showModal = false
      this.$swal({
        title: "Success!",
        text: "Your book cover selection process is complete!",
        icon: "success",
      })
          .then(() => {
            this.$router.push('/checkout')
          });
    }
  },
  watch: {
    imageUrlParams: {
      handler: function () {
        this.getImages()
      },
      deep: true
    }
  }
}
</script>