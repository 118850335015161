<template>
    <div>
        <footer>
            <div class="container py-5">
                <div class="row footer-top">
                    <div class="col-md-4">
                        <h4 class="text-bold">Black Baby</h4>
                        <p>With 2.3 million variations, you can create a truly unique personalised book.</p>
                    </div>
                    <div class="col-md-3">
                        <h6>Help</h6>
                        <div class="footer-icons">
                            <li><router-link to="/contact">Contact Us</router-link></li>
                            <li><router-link to="/signin">Sign in / Join</router-link></li>
                            <li><router-link to="/create-your-book">Create book</router-link></li>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <h6>Discover</h6>
                        <div class="footer-icons">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/stories">Stories</router-link></li>
                            <li><router-link to="/about">About</router-link></li>
                            <li><router-link to="">Gallery</router-link></li>
                            <!--<li><router-link to="/blog">Blog</router-link></li>-->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <h6>Socials</h6>
                        <div>
                            <p>Reach us via any of our social media pages</p>
                        </div>
                        <div class="footer-icons">
                            <a href="https://instagram.com/blackbabyworld"><span class="ti-instagram"></span></a>
                            <a href=""><span class="ti-facebook"></span></a>
                            <a href=""><span class="ti-twitter"></span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom bg-grey pt-2 pb-1">
                <div class="container">
                    <div class="footer-flex">
                        <div>
                            <a href="">Privacy</a>
                            <a href="">Terms</a>
                            <a href="">About</a>
                        </div>
                        <div>
                            <p>&copy; {{(new Date).getFullYear()}} Black Baby World LLC</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <section class="mobile-nav-wrapper">
            <div class="mobile-nav">
                <div class="mobile-nav-item">
                    <router-link to="/stories">
                        <div>
                            <span class="ti-layout-media-center-alt"></span>
                        </div>
                        <div>Stories</div>
                    </router-link>
                </div>
                <div class="mobile-nav-item">
                    <router-link to="/about">
                        <div>
                            <span class="ti-info-alt"></span>
                        </div>
                        <div>About</div>
                    </router-link>
                </div>
                <div class="mobile-nav-item">
                    <router-link to="/create-your-book">
                        <div>
                            <span class="ti-plus"></span>
                        </div>
                        <div>Create</div>
                    </router-link>
                </div>
                <div class="mobile-nav-item">
                    <router-link to="/contact">
                        <div>
                            <span class="ti-map-alt"></span>
                        </div>
                        <div>Contact</div>
                    </router-link>
                </div>
                <div class="mobile-nav-item">
                    <a href="https://www.amazon.com/s?i=stripbooks&rh=p_27%3AUgo+Arthur+Ezeoke&s=relevancerank&text=Ugo+Arthur+Ezeoke&ref=dp_byline_sr_book_1" target="_blank">
                        <div>
                            <span class="ti-bag"></span>
                        </div>
                        <div>Store</div>
                    </a>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>